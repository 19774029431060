import { ROUTE_AUTHENTICATION } from '@/app/routes';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { RedirectState } from '@/types/RedirectState';
import { getAuthenticated } from '@/services/authSlice';
import { useAppSelector } from '@/app/hooks';
import React, { FC } from 'react';

type Props = RouteProps;

export const PrivateRoute: FC<Props> = (props: Props) => {
  const { ...other } = props;

  const location = useLocation<RedirectState>();
  const state: RedirectState = { redirectTo: location.pathname };

  const isAuthenticated: boolean = useAppSelector(getAuthenticated);

  if (isAuthenticated) {
    return <Route {...other} />;
  } else {
    return <Redirect to={{ pathname: ROUTE_AUTHENTICATION, state: state }} />;
  }
};
