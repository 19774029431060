import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/app/store';
import { UserDTO } from '@/types/user/UserDTO';

export interface AuthState {
  user?: UserDTO;
  isAuthenticated: boolean;
}

const initialState: AuthState = {
  user: undefined,
  isAuthenticated: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setUser: (state: AuthState, action: PayloadAction<UserDTO | undefined>): void => {
      state.user = action.payload;
    },
    signIn: (state: AuthState, action: PayloadAction<UserDTO>): void => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    signOut: (state: AuthState): void => {
      state.user = undefined;
      state.isAuthenticated = false;
    },
  },
});

export const { setUser, signIn, signOut } = authSlice.actions;

export const getCurrentUser = (state: RootState): UserDTO | undefined => {
  return state.auth.user;
};

export const getAuthenticated = (state: RootState): boolean => {
  return state.auth.isAuthenticated;
};

export default authSlice.reducer;
