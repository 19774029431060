import { Grid, useMediaQuery } from '@mui/material';
import { MerchDTO } from '@/types/MerchDTO';
import { MerchItem } from '@/components/main/merch/MerchItem';
import { Theme } from '@mui/material/styles';
import React, { FC, ReactNode } from 'react';

interface Props {
  merchItems: MerchDTO[];
}

export const MerchItemsBlock: FC<Props> = (props: Props) => {
  const { merchItems } = props;

  const isMobile: boolean = useMediaQuery((theme: Theme): string => {
    return theme.breakpoints.down('sm');
  });

  return (
    <Grid container={true} direction={'row'} spacing={isMobile ? 1.5 : 2}>
      {(merchItems || []).map((merch: MerchDTO, index: number): ReactNode => {
        return (
          <Grid item={true} key={index} xs={6} md={3}>
            <MerchItem merch={merch} href={merch?.redirectUrl} />
          </Grid>
        );
      })}
    </Grid>
  );
};
