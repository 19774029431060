import { AtlasRegionDTO } from '@/types/atlas/AtlasRegionDTO';
import { CreateFeedbackDTO } from '@/types/help/CreateFeedbackDTO';
import { FaqMainInfoDTO } from '@/types/help/FaqMainInfoDTO';
import { FeedbackDTO } from '@/types/help/FeedbackDTO';
import { HelpContentDTO } from '@/types/help/HelpContentDTO';
import { HttpMethod } from '@/types/HttpMethod';
import { apiSlice } from '@/services/api/apiSlice';

export const helpApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAtlasRegions: build.query<AtlasRegionDTO[], void>({
      query: () => ({
        url: '/atlas/regions',
        method: HttpMethod.GET,
      }),
    }),
    getClientFaq: build.query<FaqMainInfoDTO, void>({
      query: () => ({
        url: '/static/client-faq',
        method: HttpMethod.GET,
      }),
    }),
    getClientWorksInfo: build.query<HelpContentDTO, void>({
      query: () => ({
        url: '/static/it-works',
        method: HttpMethod.GET,
      }),
    }),
    sendFeedback: build.mutation<FeedbackDTO, { dto: CreateFeedbackDTO; reCaptchaToken?: string }>({
      query: ({ dto, reCaptchaToken }) => ({
        url: '/feedback',
        method: HttpMethod.POST,
        body: dto,
        headers: {
          'Re-Captcha-Token': reCaptchaToken,
        },
      }),
    }),
    getAllLettersCount: build.query<number, void>({
      query: () => ({
        url: '/static/count',
        method: HttpMethod.GET,
      }),
    }),
  }),
});

export const {
  useGetAtlasRegionsQuery,
  useGetClientFaqQuery,
  useGetClientWorksInfoQuery,
  useSendFeedbackMutation,
  useGetAllLettersCountQuery,
} = helpApiSlice;
