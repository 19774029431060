import { Grid, Typography } from '@mui/material';
import { ROUTE_REGISTRATION } from '@/app/routes';
import { RedirectState } from '@/types/RedirectState';
import { RouteLink } from '@/components/common/RouteLink';
import { Theme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import React, { FC } from 'react';

export const UnauthorizedPaymentBalanceSignUp: FC = () => {
  const location = useLocation<RedirectState>();
  const state: RedirectState = { redirectTo: location.pathname };
  return (
    <Grid
      container={true}
      wrap={'nowrap'}
      alignItems={'center'}
      sx={(theme: Theme) => ({
        backgroundColor: theme.colors.blueBackgroundInfo,
        padding: '4px 8px',
        borderRadius: '6px',
        gap: '8px',
        boxShadow: '0px 2px 10px 0px rgba(99, 135, 230, 0.6)',
      })}>
      <Grid item={true} pr={1}>
        <LockIcon sx={{ display: 'block', color: '#ffffff' }} />
      </Grid>
      <Grid item={true}>
        <Typography variant={'body2'} color={'white'} whiteSpace={'pre-wrap'} component={'div'}>
          <Grid container={true}>
            <Grid item={true}>{'Создайте '}</Grid>
            <Grid item={true}>
              <Typography variant={'inherit'} sx={{ textDecoration: 'underline' }}>
                <RouteLink
                  to={{
                    pathname: ROUTE_REGISTRATION,
                    state: state,
                  }}>
                  {'личный кабинет'}
                </RouteLink>
              </Typography>
            </Grid>
            <Grid item={true}>{' для продолжения'}</Grid>
          </Grid>
        </Typography>
      </Grid>
    </Grid>
  );
};
