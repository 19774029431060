import { HttpMethod } from '@/types/HttpMethod';
import { MerchDTO } from '@/types/MerchDTO';
import { apiSlice } from '@/services/api/apiSlice';

export const merchApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getMerchItems: build.query<MerchDTO[], void>({
      query: () => ({
        url: '/merch/active',
        method: HttpMethod.GET,
      }),
    }),
  }),
});

export const { useGetMerchItemsQuery } = merchApiSlice;
