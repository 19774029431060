import { Box, Button, ButtonProps } from '@mui/material';
import { MerchDTO } from '@/types/MerchDTO';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';

interface Props extends ButtonProps {
  merch: MerchDTO;
}

export const MerchItem: FC<Props> = (props: Props) => {
  const { merch, href, ...other } = props;

  const handleNavigation = (): void => {
    if (href) {
      window.open(href, '_blank');
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        paddingTop: '100%',
      }}>
      <Button
        variant={'contained'}
        color={'secondary'}
        onClick={handleNavigation}
        sx={(theme: Theme) => ({
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          borderRadius: theme.spacing(2),
          padding: theme.spacing(1.5, 2),
          stroke: theme.colors.primary,
          backgroundImage: `url(${merch.imageUrl})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        })}
        {...other}
      />
    </Box>
  );
};
